export default class {
    /**
     * 获取用户权限
     *
     * @param {*} {GET}
     * @returns
     */
    getUserPermisson({ GET }) {
        return GET('/client/menu/userMenu')
    }

    // 获取地址片区
    getAddressArea({ GET }, provinceId) {
        // console.log('getAddressArea', provinceId)
        return GET(`/client/client/getAreaByAddress/${provinceId}`, { __noErrorInfo: true })
    }

    getCompanyInfo({GET}, params) {
        return GET(`/api/account/company/info`)
    }

}