import React from 'react'
import { Select, Spin } from 'antd'
import rApi from '@src/http'
import { isNumber } from '@src/libs/util'

const { Option } = Select
const resListKeys: string[] = ['records', 'list', 'clients']

interface IProps{
    onChange?: any;
    handleChange? :any;
    dealRes?: any;
    keyName: string;
    labelName: string;
    isSimpleArray: boolean;
    apiName: string;
    params?: any;
    getRes?: any;
    def?: any;
    value?: any;
    numberKey: boolean;
}

interface IState {
    loading: boolean;
    list: any[];
}

class ReqSelect extends React.Component<IProps, IState> {
    public static defaultProps = {
        keyName: 'id',
        labelName: 'text',
        isSimpleArray: false,
        numberKey: false
    }
    public defValue = {}
    public val = {}

    constructor (props:IProps) {
        super(props)
        this.state = {
            loading: false,
            list: [],
        }
        const { def, isSimpleArray, keyName } = props
        if ('def' in props) {
            if (isSimpleArray) {
                this.defValue = {
                    defaultValue: def
                }
            } else {
                this.defValue = (isNumber(def[keyName]) || !!def[keyName]) ? {
                    defaultValue: def[keyName]
                } : {}
            }
            this.state.list.push(def)
        }
    }
    
    public onChange = async (val: any, opt: any) => {
        const { list } = this.state
        const { handleChange, onChange, keyName, labelName, isSimpleArray, numberKey } = this.props
        // console.log('onChange', val, opt)

        let target
        if (isSimpleArray) {
            target = list.find(item => item === val) || null
        } else {
            target = list.find(item => `${item[keyName]}` === val) || { [keyName]: null, [labelName]: null }
        }
        if (handleChange) {
            handleChange(numberKey ? parseInt(val, 10) : val, target)
        }
        if (onChange) {
            onChange(val, opt)
        }
    }

    public onDropdownVisibleChange = (open: boolean) => {
        const { list } = this.state
        if (open && list.length < 2) {
            this.getList()
        }
    }

    public getList = async () => {
        try {
            this.setState(({ loading: true }))
            const { apiName, params, dealRes, getRes } = this.props
            let res = []
            if (!!getRes) {
                res = await getRes()
            } else {
                res = await rApi[apiName](params)
            }
            let rt: any[] = res instanceof Array ? [...res] : []
            if (!!dealRes) {
                rt = dealRes(res)
            } else {
                // tslint:disable-next-line: prefer-for-of
                for (let i = 0; i < resListKeys.length; i++) {
                    const key = resListKeys[i]
                    if (res[key] instanceof Array) {
                        rt = res[key]
                        break
                    }
                }
            }
            this.setState({ list: rt, loading: false })
        } catch (error) {
            console.log('getList Error', error)
            this.setState({ list: [], loading: false })
        }
    }

    public render () {
        const { loading, list } = this.state
        const {
            onChange,
            handleChange,
            keyName,
            labelName,
            isSimpleArray,
            value,
            ...restProps
        } = this.props
        // console.log('render', list, this.defValue)
        const loadingRender: any = loading ? {
            dropdownRender: () => (
                <Spin size='small' tip='加载中...' />
            ),
            dropdownStyle: {
                textAlign: 'center',
                padding: '12px 0'
            }
        } : {}
        this.val = 'value' in this.props && (isNumber(value) || (typeof value === 'string')) ? { value: `${value}` } : {}
        return (
            <Select
                allowClear={true}
                loading={loading}
                {...this.defValue}
                {...this.val}
                {...loadingRender}
                onChange={this.onChange}
                onDropdownVisibleChange={this.onDropdownVisibleChange}
                {...restProps}
            >
                {
                    list.map(item => isSimpleArray ? (
                        <Option key={item} title={item}>
                            {item}
                        </Option>
                    ) : (
                        <Option key={item[keyName]} title={item[labelName]}>
                            {item[labelName]}
                        </Option>
                    ))
                }
            </Select>
        )
    }
}
 
export default ReqSelect
