import RequestMethod from './http';
import * as allReq from '../libs/load-services';
import axios from 'axios';
import { REQUEST_METHOD } from './http';
import _ from 'lodash';
// import { connect } from 'dva';
// import Api from '../api';
// console.log('allReq', allReq)
// tslint:disable-next-line:variable-name
const allServices = allReq.services

// console.log('services', services)
/**
 * Http request constructor entry
 *
 * @class RequestApi
 * @extends {RequestMethod}
 */

class RequestApi extends RequestMethod {

    public requestPool: any[] = []
    private _http: any = {}
    
    constructor(props: any) {
        super(props);
        this._http = props;
        // setTimeout(() => {
        //     console.log('RequestApi', app)
        //     /* tslint:disable */
        //     const a: any = app
        //     this._store = a['_store']
        //     /* tslint:enable */
        // })
    }
}

const CancelToken = axios.CancelToken

const allNetWorkerRequest:any = {}


/** 
 * 组合各个模块的api请求
 */
export default ((props:any) => {
    const requestApi:any = new RequestApi(props)
    for (const key of _.keys(allServices)) {
        // console.log('allServices key', key, allServices)
        if (!requestApi[key]) {
            if (allServices && typeof allServices[key] === 'object') {
                const api = allServices[key]
                for (const name of _.keys(api)) {
                    // console.log('allServices key name', name)
                    if (name !== 'constructor') {
                        /** 
                         * 注册请求方法 并确定每个接口请求时结束上次请求, 保证接口请求仅有最后一次做出响应
                         */
                        requestApi[name] = (params: any, 
                            options: any = {
                                __isConcurrent: false
                            }
                        ) => {
                            if (!allNetWorkerRequest[name]) {
                                allNetWorkerRequest[name] = {
                                    request: null,
                                    cancel: null
                                }
                            }
                            if (allNetWorkerRequest[name].cancel && typeof allNetWorkerRequest[name].cancel === 'function' && !options.__isConcurrent) {
                                allNetWorkerRequest[name].cancel('cancel request')
                                // console.log('allNetWorkerRequest[name].cancel', allNetWorkerRequest[name].cancel)
                            }
                            const source:any = new CancelToken(executor => {
                                allNetWorkerRequest[name].cancel = executor
                            })
                            allNetWorkerRequest[name].cancelToken = CancelToken.source()
                            const reqMethod: any = {}
                            for (const methodKey of Object.keys(REQUEST_METHOD)) {
                                reqMethod[methodKey] = (url: string, data: any, config: any) => {
                                    return requestApi[methodKey](url, { _originData: data, _requestID: name, cancelToken: source, config })
                                }
                            }
                            
                            allNetWorkerRequest[name].request = api[name](reqMethod, params, options)
                            // console.log('allNetWorkerRequest', allNetWorkerRequest)
                            requestApi.requestPool = allNetWorkerRequest
                            return allNetWorkerRequest[name].request
                        }
                    }
                }
            } else {
                requestApi[key] = allServices[key]
            }
        } else {
            console.error(`请求Api${key}名字发生重复`);
        }
    }
    // console.log('requestApi', requestApi.getAddressArea.toString())
    return requestApi
})({})