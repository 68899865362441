import React, { useEffect, useState } from 'react'
import { Input, Icon, Modal, Button } from 'antd'
import PropTypes from 'prop-types'
import { HandsomeSpin } from '@src/components/handsome'
import { Map, Marker } from 'react-amap'
import { aMapKey, aMapVersion, aMapStyle } from '@src/config'
import AddressCascader from './index'
import { concatAddrAndDetails, getAddrJsonVal } from '@src/libs/logic'

function Location({
    address,
    details,
    loccationRefresh,
    closeAddressMap
}) {
    const [marker, setMarker] = useState(null)
    const [addr, setAddr] = useState({})
    const [addrVals, setAddrVals] = useState([])
    const [text, setText] = useState('')
    const [areaId, setAreaId] = useState(null)
    const [areaName, setAreaName] = useState('')
    // console.log('a', poiPicker)

    const geocoderOpt = {
        radius:1000, //以已知坐标为中心点，radius为半径，返回范围内兴趣点和道路信息
        extensions: "all"//返回地址描述以及附近兴趣点和道路信息，默认"base"
    }

    const getLocationCb = (status, result) => {
        if (status === 'complete' && result.info === 'OK') {
            // console.log('getLocationCb', result)
            const { geocodes } = result
            if (geocodes && geocodes.length > 0) {
                const poi = geocodes[0]
                poi.location ? setMarker({
                    longitude: poi.location.lng,
                    latitude: poi.location.lat
                }) : setMarker(null)
            } else {
                setMarker(null)
            }
        } else {
            setMarker(null)
        }
    }

    // const getAddressCb = (status,result) => {
    //     if (status === 'complete' && result.regeocode) {
    //         let address = result.regeocode.formattedAddress;
    //         let data = result.regeocode.addressComponent
    //         let name = data.township +data.street + data.streetNumber

    //         console.log('getAddressCb', result, address, data, name)

    //         // setMarker({
    //         //     longitude: poi.location.lng,
    //         //     latitude: poi.location.lat
    //         // })
          
    //         // _this.changeData(address,'addr')
    //         // _this.changeData(name,'name')
    //         // _this.changeData(e.lnglat.lng,'longitude')
    //         // _this.changeData(e.lnglat.lat,'latitude')
    //         // _this.setState({isChose:true})
    //     }
    // }

    const getLoc = (str) => {
        // console.log('getLoc', str)
        const geocoder = new window.AMap.Geocoder(geocoderOpt)
        geocoder.getLocation(str, getLocationCb)
    }

    const events = {
        created: ins => {
            window.AMap.plugin(["AMap.Geocoder"], function(){
                const str = getAddrJsonVal(concatAddrAndDetails(address, details), 'formatAddress')
                getLoc(str)
            })
        },
        // click: e => {
        //     window.AMap.plugin(["AMap.Geocoder"],function(){
        //         const geocoder = new window.AMap.Geocoder(geocoderOpt)
        //         geocoder.getAddress(e.lnglat, getAddressCb)
        //     })
        // }
    }

    const onAddrChange = ({ address, areaId, areaName, vals }) => {
        // console.log('ad', address)
        setAddr(address)
        setAddrVals(vals)
        setAreaId(areaId)
        setAreaName(areaName)
    }

    const onTextChange = (e) => {
        setText(e.target.value)
    }

    const onLocation = () => {
        window.AMap.plugin(["AMap.Geocoder"],function(){
            const str = getAddrJsonVal(concatAddrAndDetails(addr, text), 'formatAddress')
            // console.log(1, str)
            getLoc(str)
        })
    }

    const onConfirm = () => {
        // console.log('onConfirm', marker, addr, text)
        setAddr({
            ...addr,
            location: marker
        })
        loccationRefresh && loccationRefresh({ marker, addr, text, areaId, areaName, addrVals })
        closeAddressMap()
    }
    
    // useEffect(() => {
    //     // console.log('ui', uiLoaded)
    //     uiLoaded && initAMapUI()
    // })

    useEffect(() => {
        // const str = getAddrJsonVal(concatAddrAndDetails(address, details), 'formatAddress')
        // console.log('concatAddrAndDetails', address, details, str)
        // console.log('>>>>>', str, window.AMap)
        setAddr(address)
        setText(details)
    }, [address, details])

    return(
        <Map
            amapkey={aMapKey}
            version={aMapVersion}
            loading={<HandsomeSpin />}
            mapStyle={aMapStyle}
            center={marker}
            events={events}
        >
            {
                marker && <Marker
                        position={marker}
                        label={{
                            content: `当前经纬度：(${ marker ? marker.longitude : ''} , ${ marker ? marker.latitude : ''})`,
                            offset: { x: -100, y: -23 }
                        }}
                    />
            }
            <div className='suspension_action_area'>
                <div className='addr'>
                    <AddressCascader
                        style={{ width: '320px', flexShrink: 0 }}
                        onAddressChange={onAddrChange}
                        // addrDetails={addrDetails}
                        defValues={addr}
                    />
                    <Input
                        // disabled={edit}
                        style={{ width: '180px', marginLeft: 4, top: 0, flexShrink: 0 }}
                        placeholder=''
                        addonAfter={
                            <Icon type='environment' style={{ cursor: 'pointer' }} onClick={onLocation} />
                        }
                        onChange={onTextChange}
                        value={text}
                    />
                </div>
                
                <Button type='primary' onClick={onConfirm}>确定</Button>
            </div>
        </Map>
    )
}

class Details extends React.Component {
    static propTypes = {
        hasMapSet: PropTypes.bool, // 是否有map设置
        onAddressDetailsChange: PropTypes.func,
        address: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string
        ]), // 地址json格式
        defValues: PropTypes.string, // 默认值
    }
    
    static defaultProps = {
        hasMapSet: false,
        address: {},
        defValues: ''
    }

    constructor (props) {
        super(props)
        this.state = {
            value: null,
            visible: false
        }
        this.state.value = props.defValues ? props.defValues : ''
    }

    onChange = (e) => {
        const { onChange, onAddressDetailsChange } = this.props
        this.setState({ value: e.target.value })
        onAddressDetailsChange && onAddressDetailsChange(e.target.value)
        onChange && onChange(e)
    }

    showAddressMap = () => {
        // console.log('showAddressMap', this.props)
        this.setState({ visible: true })
    }

    closeAddressMap = () => {
        this.setState({ visible: false })
    }

    render () {
        let { value, visible } = this.state
        const { hasMapSet, address, loccationRefresh, defValues } = this.props
        value = defValues ? defValues : ''
        return (
            <React.Fragment>
                {
                    hasMapSet && (
                        <Modal
                            getContainer={false}
                            wrapClassName='global_address_maplocation_modal'
                            width={680}
                            bodyStyle={{ padding: '0', background: '#fff', color: '#484848', position: 'relative', height: 400 }}
                            title="地图定位"
                            visible={visible}
                            onCancel={() => this.setState({ visible: false })}
                            centered
                            footer={null}
                            destroyOnClose
                            maskClosable={false}
                        >
                            <Location
                                address={address}
                                details={value}
                                loccationRefresh={loccationRefresh}
                                closeAddressMap={this.closeAddressMap}
                            />
                        </Modal>
                    )
                }
                <Input
                    // disabled={edit}
                    placeholder='详细地址'
                    addonAfter={hasMapSet ? <Icon type='environment' style={{ cursor: 'pointer' }} onClick={this.showAddressMap} /> : null}
                    onChange={this.onChange}
                    value={value}
                />
            </React.Fragment>
        )
    }
}

export default Details