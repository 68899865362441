/** 
 *  View type definition, servives Method Transfer
 */
import { REQUEST_METHOD } from '@src/http/http'

export const aMapKey: string = '19533afb903872e7c46d0f69ce8d1be7' // 高德地图key
export const aMapVersion: string = '1.4.15' // 当前使用的高德地图版本
export const aMapStyle = 'amap://styles/16ed786638273b27968cecd4a79938fd' // 自定义高德地图主题样式

export const serviveMethod = REQUEST_METHOD

export const fullscreenDomId: string = 'content_fullscreen' // 全屏dom id
export const headerHeight: number = 46 // 头部栏高度
export const collapsedWidth = 40 // 侧边栏收缩时的宽度
export const unCollapsedWidth = 200 // 侧边栏宽度

/**
 * view： 页面显示类别
 * page：页面
 * pageHide：非导航页面
 * category：导航类别
 */
export const viewType = {
    view: 'view',
    page: 'page',
    pageHide: 'page_hide',
    category: 'category'
}

export const RENDER_METHOD: {
    route: string;
    tabs: string;
} = {
    route: 'route',
    tabs: 'tabs'
}

// 权限树过滤项 main id 数组
export const filterAuthItems = [
    '/monitor/transportOrderSearch',
    'login'
]

export const homeId: string = '/home'
