import { stringify } from 'querystring';
import InitAxios from './instance';
import _ from 'lodash';
// import { message } from 'antd'

export const REQUEST_ERROR_TYPE = {
    BROWSER: 'BROWSER',
    NETWORK: 'NETWORK',
    RESTFUL_API: 'RESTFUL_API',
    PROJECT: 'PROJECT',
}

/** 
 *  @function 描述请求方式
 *  POST 默认json格式
 *  DELETE 当传入参数中存在 id 时会自动请求url格式为`${url}/${params.id}`
 *  PUT 默认json格式
 */
export const REQUEST_METHOD = {
    GET: 'GET',
    // GET_JSON: 'GET_JSON',
    GET_FILE: 'GET_FILE',
    POST: 'POST',
    POST_FORM: 'POST_FORM',
    POST_FILE: 'POST_FILE', // 获取文件
    POST_UPLOAD: 'POST_UPLOAD', // 上传文件
    POST_QUERY: 'POST_QUERY',
    DELETE: 'DELETE',
    PUT: 'PUT',
    PUT_FORM: 'PUT_FORM',
    POST_FORM_EXPORT: 'POST_FORM_EXPORT'
}

const codeToText = (res: any) => {
    return {
        message: res.message || res.msg
    }
}

/** 
 * request error catch reject standard error msg
 */
const onError = (reject: any, error: any, _receiveCancelRequest: any) => {
    // console.log('error', JSON.parse(JSON.stringify(error)))
    if (error && error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
            // message.error('登录失效！')
            reject({
                message: (error && (error.message || error.msg)) || error,
                type: REQUEST_ERROR_TYPE.RESTFUL_API
            })
        } else {
            reject(error.response.data)
        }
    } else if (error && error.message && error.message === 'cancel request') {
        if (_receiveCancelRequest) {
            reject({cancelRequest: true})
        }
    } else if (error && error.request) {
        // console.log('error.message', error, JSON.parse(JSON.stringify(error)), error.message)
        reject({
            message: (error.message || error.msg),
            type: REQUEST_ERROR_TYPE.NETWORK
        })
    } else {
        reject({
            message: (error && (error.message || error.msg)) || 'UNKONE ERROR',
            type: REQUEST_ERROR_TYPE.BROWSER
        })
    }
}

/** 
 * Processing request result is error(reject) and success(resolve)
 */
const errorHandling = (resolve: any, reject: any, res: any) => {
    // console.log('resolve', res)
    if (typeof res === 'object') {
        if ('logo' in res) {
            resolve(res)
        }
        if (('status' in res && res.status < 400 && !res.code && res.code !== '0' && res.code !== 0) || ('success' in res && res.success)) {
            if (res.access_token) {
                resolve(res)
            }
            if ('status' in res || 'success' in res) {
                resolve(res.data)
            } else {
                resolve(res)
            }
        } else if (!('success' in res) && !res.status && !res.code && res.code !== '0' && res.code !== 0) {
            // console.log('errorHandling2')
            resolve(res)
        } else if (('success' in res) && res.success && ('data' in res)) {
            // console.log('errorHandling3')
            resolve(res.data)
        } else {
            // console.log('errorHandling4')
            reject({
                type: REQUEST_ERROR_TYPE.PROJECT,
                message: codeToText(res).message
            })
        }
    } else {
        resolve(res)
    }
}

function beStrippedReqData(params: any): { data: any; cancelToken: any; _receiveCancelRequest: boolean} {
    let cancelToken = {}
    let _receiveCancelRequest = false
    if (params && params.cancelToken) {
        cancelToken = {
            cancelToken: params.cancelToken
        }
        delete params.cancelToken
    }
    if (params && params._requestID) {
        delete params._requestID
    }

    if (params && params._receiveCancelRequest) {
        _receiveCancelRequest = true
        delete params._receiveCancelRequest
    }
    return {
        data: params && params._originData,
        cancelToken,
        _receiveCancelRequest
    }
}

export default class RequestMethod extends InitAxios {
    
    public [REQUEST_METHOD.GET](url: string, params: any) {
        const d = params._originData ? params._originData : {}
        const http = this._getInstance(d)
        return new Promise((resolve, reject) => {
            const {
                cancelToken,
                data,
                _receiveCancelRequest
            } = beStrippedReqData(params);
            // console.log('get', cancelToken)
            http.get(url, {
                params: data
            }, cancelToken).then((response: any) => {
                errorHandling(resolve, reject, response.data)
            }).catch((error: any) => {
                // console.log('GET catch error')
                onError(reject, error, _receiveCancelRequest)
                reject(error)
            })
        })
    }

    public [REQUEST_METHOD.GET_FILE](url: string, d: any) {
        const http = this._getInstance()
        return new Promise((resolve, reject) => {
            const {
                cancelToken
            } = beStrippedReqData(d);
            http.get(url, {
                params: d,
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }, cancelToken).then((response: any) => {
                resolve(response)
            }).catch((error: any) => {
                // console.log('GET catch error')
                reject(error)
            })
        })
    }

    public [REQUEST_METHOD.POST_FILE](uri: string, params: any) {
        const http = this._getInstance()
        const promise = http.post(uri, stringify(params._originData), {
            responseType: 'blob',
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
        return promise
    }
    
    public [REQUEST_METHOD.POST_FORM](url: string, params: any) {
        const http = this._getInstance()
        return new Promise((resolve, reject) => {
            // console.log('FORM_POST', params)
            const {
                cancelToken,
                data,
                _receiveCancelRequest
            } = beStrippedReqData(params);
            http.post(
                url,
                stringify(data),
                {
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'}, 
                    ...cancelToken
                }
            ).then((response: any) => {
                errorHandling(resolve, reject, response.data)
            }).catch((error: any) => {
                // console.error('FORM_POST error', error, JSON.parse(JSON.stringify(error)))
                onError(reject, error, _receiveCancelRequest)
            })
        })
    }

    public [REQUEST_METHOD.POST_FORM_EXPORT](url: string, params: any) {
        const http = this._getInstance()
        return new Promise((resolve, reject) => {
            // console.log('FORM_POST', params)
            const {
                cancelToken,
                data,
            } = beStrippedReqData(params);
            http.post(
                url,
                stringify(data),
                {
                    responseType: 'blob',
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'}, 
                    ...cancelToken
                }
            ).then((response: any) => {
                resolve(response)
            }).catch((error: any) => {
                // console.error('FORM_POST error', error, JSON.parse(JSON.stringify(error)))
                reject(error)
            })
        })
    }

    public [REQUEST_METHOD.POST_UPLOAD](url: string, params: any) {
        const http = this._getInstance()
        // console.log('params.config.onUploadProgress', params);
        return new Promise((resolve, reject) => {
            // console.log('FORM_POST', params, querystring.stringify(params))
            const {
                cancelToken,
                data,
                _receiveCancelRequest
            } = beStrippedReqData(params);
            // console.log('params.config.onUploadProgress', params.config.onUploadProgress);
            http.post(
                url,
                data,
                {
                    onUploadProgress: params && params.config && params.config.onUploadProgress,
                    headers: {'Content-Type': 'amultipart/form-data'}, 
                    ...cancelToken
                }
            ).then((response: any) => {
                errorHandling(resolve, reject, response.data)
            }).catch((error: any) => {
                onError(reject, error, _receiveCancelRequest)
            })
        })
    }
    
    public [REQUEST_METHOD.POST](url: string, params: any) {
        const http = this._getInstance()
        return new Promise((resolve, reject) => {
            const {
                cancelToken,
                data,
                _receiveCancelRequest
            } = beStrippedReqData(params);
            http.post(url, data, {headers: {'Content-Type': 'application/json'}, ...cancelToken}).then((response: any) => {
                errorHandling(resolve, reject, response.data)
            }).catch((error: any) => {
                onError(reject, error, _receiveCancelRequest)
            })
        })
    }

    public [REQUEST_METHOD.POST_QUERY](url: string, params: any) {
        const http = this._getInstance()
        return new Promise((resolve, reject) => {
            const {
                cancelToken,
                data,
                _receiveCancelRequest
            } = beStrippedReqData(params);
            http({
                method: 'post',
                url,
                params: data,
                headers: {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    ...cancelToken
                }
            }).then((response: any) => {
                errorHandling(resolve, reject, response.data)
            }).catch((error: any) => {
                onError(reject, error, _receiveCancelRequest)
            })
        })
    }

    public [REQUEST_METHOD.DELETE](url: string, params: any) {
        const http = this._getInstance()
        const getId = (data: any) => {
            if (_.isArray(data) && data.length === 1) {
                return data[0]
            } else if (data && data.id) {
                return data.id
            }
            return false
        }
        return new Promise((resolve, reject) => {
            const {
                cancelToken,
                data,
                _receiveCancelRequest
            } = beStrippedReqData(params);
            // console.log('getId(data)', getId(data), data, params)
            http({
                method: 'DELETE',
                url: getId(data) ? `${url}/${getId(data)}` : url,
                // params: data,
                data: stringify(data),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                ...cancelToken
            }).then((response: any) => {
                errorHandling(resolve, reject, response.data)
            }).catch((error: any) => {
                onError(reject, error, _receiveCancelRequest)
            })
        })
    }

    public [REQUEST_METHOD.PUT](url: string, params: any) {
        const http = this._getInstance()
        return new Promise((resolve, reject) => {
            const {
                cancelToken,
                data,
                _receiveCancelRequest
            } = beStrippedReqData(params);
            http.put(url, data, {headers: {'Content-Type': 'application/json'}, ...cancelToken}).then((response: any) => {
                errorHandling(resolve, reject, response.data)
            }).catch((error: any) => {
                onError(reject, error, _receiveCancelRequest)
            })
        })
    }

    public [REQUEST_METHOD.PUT_FORM](url: string, params: any) {
        const http = this._getInstance()
        return new Promise((resolve, reject) => {
            const {
                cancelToken,
                data,
                _receiveCancelRequest
            } = beStrippedReqData(params);
            http.put(url, stringify(data), {headers: {'Content-Type': 'application/x-www-form-urlencoded'}, ...cancelToken}).then((response: any) => {
                errorHandling(resolve, reject, response.data)
            }).catch((error: any) => {
                onError(reject, error, _receiveCancelRequest)
            })
        })
    }

    
}