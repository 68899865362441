import React from 'react'
import { Spin } from 'antd'

function WrapperSpin ({ children, desc, loading, switched }: any) {
    return switched ? (
        loading ? (
            <Spin
                className='global_handsome_spin'
                // delay={300}
                // indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
                tip={desc}
            />
        ) : children
    ) : (
        <Spin
            wrapperClassName='global_handsome_spin_wrapper'
            // delay={300}
            // indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
            tip={desc}
            spinning={loading}
        >
            { children }
        </Spin>
    )
}
WrapperSpin.defaultProps = {
    loading: true,
    switched: true,
    desc: '加载中...'
}

export function FnSpin (loading: boolean = true) {
    return (c: React.Component) => {
        if (loading) {
            return <WrapperSpin />
        } else {
            return c
        }
    }
}

export default WrapperSpin
