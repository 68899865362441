import axios, { AxiosTransformer } from 'axios';
import headersConfig from './http.header';
import NProgress from 'nprogress';
import { app } from '@src/models'
import { message } from 'antd'
import 'nprogress/nprogress.css';

/** 
 * 增加顶部进度条
 */
function setAxiosRequestTopProgress() {
    if (axios.defaults.transformRequest && axios.defaults.transformResponse) {
        NProgress.configure({
            easing: 'linear',
            speed: 400
        });
        const transformRequest: AxiosTransformer[] = (axios.defaults.transformRequest as AxiosTransformer[]) || []
        const transformResponse: AxiosTransformer[] = (axios.defaults.transformResponse as AxiosTransformer[]) || []
        transformRequest.push((data: any, headers: any) => {
            NProgress.start();
            return data;
        });

        transformResponse.push((data: any, headers: any) => {
            NProgress.done();
            return data;
        });
    }
}


// const BASIC_AUTH = 'Basic YXBwOjEyMzQ1Ng=='
const BASIC_AUTH: string = 'Basic YXBwOjEyMzQ1Ng=='

// console.log('axios', axios);
/**
 *  init http request at axios
 *
 * @export
 * @class InitAxios
 */
export default class InitAxios {
    public _store: any
    public _instance: any
    private noErrorInfo: boolean = false

    constructor(store: any) {
        this._instance = axios.create(Object.assign({}, {
            baseURL: process.env.NODE_ENV !== 'production' ? `/comma-sscm-dev/client/api` : '/client/api',
            // baseURL: process.env.NODE_ENV !== 'production' ? ` https://120.79.113.228/commaiot/api/` : 'https://120.79.113.228/commaiot/api/',
            // baseURL: process.env.NODE_ENV !== 'production' ? `api/app/mock/19/` : '/api/',
            // baseURL: process.env.NODE_ENV !== 'production' ? `/comma-dev/api` : '/api',
            timeout: 1000 * 60 * 1.5, // 2 minutes
            headers: {
                // 'Content-Type': 'application/json'
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            withCredentials: true
        }, headersConfig));
        this._initRequestHeaders();
        this._interceptors();
        setTimeout(() => {
            setAxiosRequestTopProgress();
        })
    }

    public logOut() {
        const dvaApp: any = app
        const store = dvaApp._store
        store.dispatch({
            type: 'global/logout'
        })
    }
    
    public _initRequestHeaders() {
        const _instance = this._instance;
        _instance.interceptors.request.use((config: any) => {
            if (headersConfig.type === 1) {
                return config;
            }
            const dvaApp: any = app
            const store = dvaApp._store.getState().global
            // console.log('store', store)
            this._store = store
            _instance.store = store
            if (new RegExp('/api').test(config.url)) {
                if (process.env.NODE_ENV !== 'production') {
                    config.baseURL = '/comma-sscm-dev'
                } else {
                    config.baseURL = ''
                }
            }
            if (store.isLogin) {
                config.headers.Authorization = `Bearer ${store.accessToken}`
            } else {
                config.headers.Authorization = BASIC_AUTH
                // console.log('BASIC_AUTH', config.headers, store)
            }
            // console.log('config', config)
            return config
        }, (error: any) => {
            return Promise.reject(error)
        })
    }

    public _getInstance(params?: any) {
        this.noErrorInfo = params && params.__noErrorInfo ? params.__noErrorInfo : false
        // console.log('__noErrorInfo', this.noErrorInfo, params)
        return this._instance;
    }

    public _interceptors() {
        const _instance = this._instance;
        _instance.interceptors.response.use((response: any) => {
            // console.log('err res response', response)
            if (response.status === 202 && response.data && response.data.errcode) {
                if (response.data.errcode === 403 || response.data.errcode === 401) {
                    // console.log('exit')
                    this.logOut()
                }
            }
            if (response.data && response.data.success === false) {
                message.error(response.data.msg)
                return Promise.reject(response.data)
            }
            return response;
        }, (error: any) => {
            const code: number = error.response ? error.status || error.response.data.errcode || error.response.data.status || error.response.status : error.status
            const res = error.response && error.response.data ? error.response.data : { message: '服务器错误！' }
            const msgInfo = code === 500 ? res.message || res.error || res.error_description || res.msg : res.message || res.error || res.error_description
            // console.error('Response Error>>>>>>>', error, res)
            if (!this.noErrorInfo && error.message !== 'cancel request') {
                if (code === 2) {
                    message.error(res.msg)
                } else if (code === 401 || code === 403) {
                    message.error('token已失效，请重新登录！')
                    this.logOut()
                } else if (code === 404) {
                    message.error(`404 ${msgInfo}`)
                } else if (code === 400) {
                    message.error(res.msg || res.message || 'Request failed with status code 400')
                } else if (code === 504) {
                    message.error('请检查网络连接！')
                } else {
                    message.error(msgInfo)
                }
            }
            return Promise.reject(error);
        })
    }
}
