import React from 'react'
import { Select } from 'antd'
import { isNumber } from '@src/libs/util'

const { Option } = Select

interface IProps{
    onChange?: any;
    handleChange? :any;
    list: any[];
    keyName: string;
    labelName: string;
    isSimpleArray: boolean;
    def: any;
    value?: number | string | null | undefined;
    numberKey: boolean;
}

class DefSelect extends React.Component<IProps> {
    public static defaultProps = {
        list: [],
        keyName: 'id',
        labelName: 'text',
        isSimpleArray: false,
        numberKey: false
    }
    public defValue = {}
    public val = {}

    constructor (props:IProps) {
        super(props)
        const { def, isSimpleArray, keyName } = props
        if ('def' in props) {
            if (isSimpleArray) {
                this.defValue = {
                    defaultValue: def
                }
            } else {
                this.defValue = (isNumber(def[keyName]) || !!def[keyName]) ? {
                    defaultValue: `${def[keyName]}`
                } : {}
            }
        }
    }
    
    public onChange = (val: any, opt: any) => {
        const { handleChange, onChange, list, keyName, isSimpleArray, numberKey } = this.props
        // console.log('onChange', val, opt)
        let target
        if (isSimpleArray) {
            target = list.find(item => item === val) || null
        } else {
            target = list.find(item => `${item[keyName]}` === val) || {}
        }
        if (handleChange) {
            handleChange(numberKey ? parseInt(val, 10) : val, target)
        }
        if (onChange) {
            onChange(val, opt)
        }
    }

    public render () {
        const {
            onChange,
            handleChange,
            list,
            keyName,
            labelName,
            isSimpleArray,
            value,
            ...restProps
        } = this.props
        this.val = 'value' in this.props && (isNumber(value) || (typeof value === 'string')) ? { value: `${value}` } : {}
        return (
            <Select
                allowClear={true}
                {...this.defValue}
                {...this.val}
                onChange={this.onChange}
                {...restProps}
            >
                {
                    list.map(item => isSimpleArray ? (
                        <Option key={item} title={item}>
                            {item}
                        </Option>
                    ) : (
                        <Option key={item[keyName]} title={item[labelName]}>
                            {item[labelName]}
                        </Option>
                    ))
                }
            </Select>
        )
    }
}
 
export default DefSelect
