// import React from 'react';
// import ReactDOM from 'react-dom';
import { app, initGlobalModels } from '@src/models'
import 'moment/locale/zh-cn'
import routerInit from './router'
import '@src/assets/styles/public.less'
import * as serviceWorker from './serviceWorker'

initGlobalModels();
routerInit();
app.start('#root');

// ReactDOM.render(<Main />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register();
