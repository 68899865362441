import React from 'react'
import { DatePicker } from 'antd'
import { getRangeTime } from '@src/libs/logic'

interface IProps {
    handleChange?: any;
    format?: string;
}

interface IState {
    rangeTime: any[];
}

class RangePicker extends React.Component<IProps, IState>{
    private dateFormat = 'YYYY-MM-DD'

    constructor(props: IProps) {
        super(props)
        this.state = {
            rangeTime: []
        }
    }

    public onChange = (dates: any[], datesStr: string[]) => {
        const { handleChange, format } = this.props
        if (dates && dates.length > 0) {
            if (handleChange) {
                const rt = getRangeTime(dates, format || undefined)
                handleChange(rt.start, rt.end, dates, datesStr)
            }
        } else {
            if (handleChange) {
                handleChange(null, null, dates, datesStr)
            }
        }
        this.setState({ rangeTime: dates })
    }

    public render () {
        const { rangeTime } = this.state
        const { handleChange, ...restProps } = this.props
        return (
            <DatePicker.RangePicker
                placeholder={['开始时间', '结束时间']}
                value={rangeTime}
                format={this.dateFormat}
                onChange={this.onChange}
                {...restProps}
            />
        )
    }
}

export default RangePicker