import React, { Component } from 'react'
import { Cascader } from 'antd'
import PropTypes from 'prop-types'
// import addressData from './address.json'
import rApi from '@src/http'
import { addrItemsToAddress, getAddrJsonVal } from '@src/libs/logic'
import Details from './details'
let addreAsyn = null
const getAddressData = () => {
    if (!addreAsyn) {
        addreAsyn = import(/* webpackChunkName:"address-data" */ './address')
    }
    return addreAsyn
}

export default class AddressCascader extends Component {

    static propTypes = {
        onAddressChange: PropTypes.func, // 地址变化
        addrDetails: PropTypes.string, // 详细地址
        defValues: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string
        ]), // 选中值
    }
    
    static defaultProps = {
        addrDetails: '',
        defValues: {}
    }

    // options = []

    constructor(props) {
        super(props)
        if(props.getInstance) {
            props.getInstance(this)
        }
        this.state = {
            addressValue: [], // 指定选中项
            addressItems: [],
            areaId: null,
            areaName: '',
            options: []
        }
        // this.options = addressData && addressData.data ? addressData.data : []
        // console.log('constructor', props)
        this.state.addressValue = props.defValues ? getAddrJsonVal(props.defValues, 'vals') : []
    }

    componentDidMount() {
        getAddressData().then(addressData => {
            this.setState({
                options: addressData && addressData.data ? addressData.data : []
            })
        })
    }

    onChange = async (vals, opts) => {
        // console.log('onChange', vals, opts)
        const { onAddressChange, onChange, addrDetails } = this.props
        let { areaId, areaName } = this.state
        await this.setState({ addressValue: vals, addressItems: opts })
        if (vals && vals.length === 1) {
            try {
                const rt = await rApi.getAddressArea(vals[0])
                if (rt && rt.length) {
                    areaId = rt.map(item => item.id).join(',')
                    areaName = rt.map(item => item.title).join(',')
                } else {
                    throw new Error('未匹配到片区')
                }
            } catch (error) {
                console.error('getAddressArea', error)
                areaId = null
                areaName = ''
            }
            await this.setState({ areaId, areaName });
        } else if (!vals.length) {
            areaId = null
            areaName = ''
            await this.setState({ areaId, areaName });
        }
        const address = addrItemsToAddress(opts, addrDetails)
        onAddressChange && onAddressChange({ vals, opts, address, areaId, areaName })
        onChange && onChange(vals, opts)
    }

    filter = (inputValue, path) => {
        return (path.some(option => (option.label).toLowerCase().indexOf(inputValue.toLowerCase()) > -1))
    }

    getAddress = (extra) => {
        const { addressItems } = this.state
        return addrItemsToAddress(addressItems, extra)
    }

    render() {
        // console.log('address_cascader', this.props)
        const { style, defValues } = this.props
        let { addressValue } = this.state
        addressValue = defValues ? getAddrJsonVal(defValues, 'vals') : []
        return (
            <Cascader
                style={{ width: '100%', ...style }}
                fieldNames={{
                    label: 'label',
                    value: 'value',
                    children: 'children'
                }}
                changeOnSelect
                options={this.state.options}
                onChange={this.onChange}
                value={addressValue}
                showSearch={{
                    filter: this.filter
                }}
                placeholder="选择地址"
            />
        )
    }
}

/**
 * 详细地址
 */
export const AddressDetails = Details