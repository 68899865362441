import React from 'react'
// import './index.less'
import { Button, Icon } from 'antd'
import { isArray } from '@src/libs/util'

interface ISpecialWidth {
    [index: number]: number;
}
export interface IProps {
    children?: any;
    handleSearch?: any;
    handleReset?: any;
    hasReset: boolean;
    specialWidth?: ISpecialWidth;
    key?: string;
}

interface IState {
    refresh: boolean;
    collapsedIndex: number;
    expended: boolean;
}

const defaultWidth = 180
class SearchBar extends React.Component<IProps, IState> {
    private static defaultProps = {
        hasReset: false
    }

    public searchBar: any = null

    private mainLine: any[] = []
    private moreLine: any[] | null = []

    constructor(props:IProps) {
        super(props)
        this.state = {
            refresh: false,
            collapsedIndex: -1,
            expended: false
        }
    }

    public componentDidMount () {
        this.setCollapsedIndex()
        window.addEventListener('resize', this.onResize)
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.onResize)
    }

    public onResize = () => {
        this.setCollapsedIndex()
    }

    public setCollapsedIndex = () => {
        const { hasReset, specialWidth, children } = this.props
        let { collapsedIndex } = this.state
        const conWidth = this.searchBar.clientWidth
        const btnboxWidth = (hasReset ? 140 : 70) + 10
        const moreBtnWidth = 47 + 10
        // console.log('c', children)
        const widthArr = isArray(children) ? children.map(() => defaultWidth) : [defaultWidth]
        if (specialWidth){
            for (const i in specialWidth) {
                if (specialWidth.hasOwnProperty(i)) {
                    const width = specialWidth[i]
                    widthArr[i] = width
                }
            }
        }
        let restWidth = conWidth - 20 - moreBtnWidth - btnboxWidth
        collapsedIndex = -1
        while(widthArr.length) {
            collapsedIndex++
            restWidth -= (widthArr.splice(0, 1)[0] + 10)
            if (restWidth < 0) {
                // console.log('rest', collapsedIndex, restWidth)
                this.setChildrenLocation(collapsedIndex)
                this.setState({ collapsedIndex })
                break
            }
        }
        if (restWidth >= 0) {
            collapsedIndex = -1
            this.setChildrenLocation(collapsedIndex)
            this.setState({ collapsedIndex })
        }
    }

    public setChildrenLocation = (collapsedIndex: number) => {
        let { children } = this.props
        children = isArray(children) ? children : [children]
        children = children.filter((item: any) => !!item)
        if (collapsedIndex !== -1) {
            this.mainLine = children.slice(0, collapsedIndex)
            this.moreLine = children.slice(collapsedIndex)
        } else {
            this.mainLine = children.slice(0)
            this.moreLine = null
        }
    }

    public onExpend = () => {
        this.setState({ expended: !this.state.expended });
    }

    public reset = (e: any) => {
        const { handleReset } = this.props
        this.setState({ refresh: true }, () => {
            this.setState({ refresh: false })
            if (handleReset) {
                handleReset(e)
            }
        })
    }

    public  render () {
        const { handleSearch, hasReset } = this.props
        const { refresh, collapsedIndex, expended } = this.state
        // console.log('render', this.mainLine, this.moreLine)
        this.setChildrenLocation(collapsedIndex)

        return (
            <div className='global_searchbar global_custom_scrollbar' ref={vm => this.searchBar = vm}>
                {
                    refresh ?
                        null
                    :
                    <React.Fragment>
                        <div className='con'>
                            { this.mainLine }
                            {
                                collapsedIndex !== -1 && (
                                    <span className='more' onClick={this.onExpend}>
                                    { expended ? '收起' : '展开' }
                                    <Icon type={expended ? 'up' : 'down'} style={{ marginLeft: 5 }} />
                                    </span>
                                )
                            }
                        </div>
                        <div className={`con_more ${(collapsedIndex !== -1 && expended) ? 'expended' : ''}`}>
                            { this.moreLine }
                        </div>
                    </React.Fragment>
                }
                <div className='btns'>
                    {
                        hasReset && <Button
                            onClick={this.reset}
                            style={{ verticalAlign: 'middle', marginRight: 10 }}
                        >
                            重置
                        </Button>
                    }
                    <Button
                        onClick={handleSearch}
                        style={{ verticalAlign: 'middle' }}
                        type='primary'
                    >
                        查询
                    </Button>
                </div>
            </div>
        )
    }
}

export default SearchBar