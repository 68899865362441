import React, { useState } from 'react'
import { Select, Spin } from 'antd'
import _ from 'lodash'
import rApi from '@src/http'

const { Option } = Select

const dataKeys: string[] = ['records', 'list', 'clients']

interface IProps{
    handleFocus?: any;
    apiName?: string;
    reqData?: any;
    labelField: string;
    handleChange?: any;
    renderType: number;
    cusRender?: any;
    children?: React.ReactChildren;
    staticData?: any;
    onChange?: any;
    dataType: string;
    valueField: string;
}

function CusSelect(props: IProps) {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])

    const { handleFocus, apiName, reqData, labelField, handleChange, renderType, cusRender, children, staticData, onChange, dataType, valueField } = props

    const onFocus = async () => {
        // console.log('r', apiName)
        try {
            setLoading(true)
            let newData: any = []
            if (staticData) {
                newData = staticData
            } else if (handleFocus) {
                newData = await handleFocus(rApi)
            } else {
                newData = await rApi[apiName!](reqData)
                if (!(newData instanceof Array)) { // eslint-disable-next-line
                    _.each(dataKeys, key => {
                        if (newData[key] instanceof Array) {
                            newData = newData[key]
                        }
                    })
                    if (!(newData instanceof Array)) {
                        newData = []
                        throw Error('数据格式化失败')
                    }
                } else {
                    newData = newData.filter(item => !!item)
                }
            }
            setData(newData)
        } catch (error) {
            console.log(error)
            setData([])
        }
        setLoading(false)
    }

    const onSelectChange = async (value: any, opt: any) => {
        const target: any = value ? (dataType === 'jsonArray' ? data[parseInt(value, 10)] : dataType === 'baseArray' ? {} : {}) : {}
        const val = value ? (dataType === 'jsonArray' ? target[valueField!] : dataType === 'baseArray' ? value : null) : null
        const label = value ? (dataType === 'jsonArray' ? target[labelField!] : dataType === 'baseArray' ? value : null) : null
        // console.log(labelField, value, val, data, target, data.find(item => item[labelField] === value))
        if (handleChange){
            handleChange(val, label, target)
        }
        if (onChange) {
            onChange(value, opt)
        }
    }

    const handleFilter = (val: any, opt: any) => {
        // const { props }: any = opt
        // console.log('val', opt, props, val)
        return opt.props.title && opt.props.title.startsWith(val)
    }
    
    // console.log('select>>>>>')

    return (
        <Select
            loading={loading}
            notFoundContent={loading ? <span><Spin /></span> : <span>暂无数据</span>}
            onFocus={onFocus}
            onChange={onSelectChange}
            filterOption={handleFilter}
            showSearch={true}
            allowClear={true}
            // size={window._size}
            // getPopupContainer={triggerNode => triggerParent ? triggerNode.parentNode : (document.querySelector('#scroll-view') || document.body)}
            {...props}
        >
            {
                renderType === 1 && data && data instanceof Array && data.length ? data.map((item, index) => {
                    const text: string = dataType === 'jsonArray' ? item[labelField] : dataType === 'baseArray' ? item : item
                    const key: any = dataType === 'jsonArray' ? index : dataType === 'baseArray' ? item : item
                    return (
                        <Option
                            key={key}
                            title={text ? text + '' : ''}
                        >
                            {
                                text
                            }
                        </Option>
                    )
                }) : null
            }
            {
                renderType === 2 && cusRender(data)
            }
            {
                renderType === 3 && children
            }
        </Select>
    );
}
CusSelect.defaultProps = {
    valueField: 'id',
    renderType: 1,
    labelField: 'id',
    dataType: 'jsonArray'
}
 
export default CusSelect
