import React from 'react'

export interface IHandsomeCardProps {
    title: string;
    className?: string;
    bodyClassName?: string;
    extra?: any;
    moreButton?: boolean;
    buttonText?: string;
    onMore?: any;
    moreStyle?: any;
}
 
export interface IHandsomeCardState {
    [key: string]: any
}

class HandsomeCard extends React.Component<IHandsomeCardProps, IHandsomeCardState> {

    public static defaultProps = {
        title: 'title',
        moreButton: false,
        buttonText: '查看详情'
    }

    public onClick = () => {
        const { onMore } = this.props
        if (onMore) {
            onMore()
        }
    }

    public render() { 
        const { title, children, className, bodyClassName, extra, moreButton, buttonText, moreStyle } = this.props
        return (
            <div className={`handsome_card ${className || ''}`}>
                <div className='handsome_card_head'>
                    <div className='left'>
                        <div className="angle" />
                        <div className="con">{ title }</div>
                    </div>
                    <div
                        className={`right ${moreButton ? 'more' : 'no_more'}`}
                        onClick={moreButton ? this.onClick : undefined}
                    >
                        <div className="con" style={moreStyle ? moreStyle : null}>
                            {
                                moreButton ? buttonText : (
                                    extra ? extra : null
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className={`handsome_card_body ${bodyClassName || ''}`}>
                    { children }
                </div>
            </div>
        )
    }
}
 
export default HandsomeCard;