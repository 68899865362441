import React from 'react'
import { Upload, Button, Icon, message } from 'antd'
import { imgClient, randomString, getSuffix } from '@src/libs/logic'
import moment from 'moment'
import _ from  'lodash'

interface IProps{
    fileList: any[];
    onFileChange?: any;
    nameKey: string;
    urlKey: string;
}

interface IState{
    fileList: any[];
}

class MyUpload extends React.Component<IProps, IState>{
    public static getDerivedStateFromProps(nextProps: IProps) {
        const { nameKey, urlKey } = nextProps
        return {
            fileList: nextProps && nextProps.fileList ? nextProps.fileList.map((item, index) => ({
                ...item,
                uid: item.uid || `uid_${index}`,
                name: item.name || item[nameKey],
                url: item.url || item[urlKey],
                thumbUrl: item.url || item[urlKey]
            })) : []
        }
    }

    private static defaultProps = {
        nameKey: 'name',
        urlKey: 'url'
    }

    private fileSuffix: string = ''
    private file: any = null

    constructor(props: IProps) {
        super(props)
        this.state = {
            fileList: []
        }
    }

    public beforeUpload = (file: any, fileList: any[]) => {
        const maxSize = file.size / 1024 / 1024 < 60
        if (!maxSize) {
            message.error('文件必须小于60MB!')
        }
        // if(!maxSize) {
        //     this.isUploadFileTypeError = !maxSize
        //     return maxSize
        // }
        const uuid = randomString()
        const s = getSuffix(fileList[0].name)
        this.fileSuffix = `${moment(new Date()).format('YYYY-MM-DD')}/${uuid}${s}`
        this.file = file
        imgClient().put(this.fileSuffix, file)
        return false
    }

    public onChange = (e: any) => {
        const { onFileChange, urlKey } = this.props
        let { fileList } = e
        fileList = fileList.map((f: any, index: number) => {
            if(f.originFileObj === this.file) {
                f[urlKey] = this.fileSuffix
            }
            return f
        })
        this.setState({
            fileList
        })
        if (!!onFileChange) {
            onFileChange(fileList, 1)
        }
    }

    public onRemove = (file: any) => { // 删除文件
        let { fileList } = this.state
        const { onFileChange } = this.props
        fileList = fileList.filter(item => item.fileUrl !== file.fileUrl)
        this.setState({
            fileList
        })
        if (!!onFileChange) {
            onFileChange(fileList, 2)
        }
        return true
    }

    public onPreview = async (file: any) => {
        const { urlKey } = this.props
        window.open(imgClient().signatureUrl(file[urlKey]), '_blank')
    }


    public render () {
        const { fileList } = this.state
        const { nameKey, urlKey, ...restProps } = this.props
        return (
            <Upload
                name= 'file'
                headers={{ authorization: 'authorization-text' }}
                {
                ...(_.isArray(fileList) && fileList.length > 0 ? { fileList } : {})
                }
                { ...restProps }
                onChange={this.onChange}
                beforeUpload={this.beforeUpload}
                onRemove={this.onRemove}
                onPreview={this.onPreview}
            >
                <Button>
                    <Icon type="upload" />
                    上传文件
                </Button>
            </Upload>
        )
    }
}
 
export default MyUpload
